<template>
	<div class="notice_popup">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기">
		</div>
		<div class="tit">추천 프로젝트 노출 {{useYnMsg[useYn]}}</div>
		<div class="member">
			<span v-if="list.length > 0">‘{{list[0].projectNm}}’<template v-if="list.length > 1">외 {{list.length - 1}}개</template></span><br>
			추천 프로젝트 노출을 {{useYnMsg[useYn]}} 하시겠습니까?
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="send()">예</div> 
		</div>
	</div>
</template>
<script>

export default {
	props: { param:Object },
	data() {
		return {
			list: this.param.list,
			useYn:this.param.useYn,
			useYnMsg:{'Y':'시작', 'N':'종료'}
		};
	},
	beforeMount(){
		if(!Array.isArray(this.param.list) || this.param.list.length == 0){
			alert('추천 프로젝트정보를 확인 할 수 없습니다.');
			this.$emit('close');
			return;
		}
	},
	methods: {
		send(){
			this.$.httpPost('/api/main/bnr/useYnRecomendPrjt', {list : this.list, useYn: this.useYn})
				.then(() => {
					alert('추천 프로젝트 노출 ' + this.useYnMsg[this.useYn] + '를 완료하였습니다.');
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
		}
	},
};
</script>
